.about-numbers {
   margin-top: 80px;
   display: grid;
   grid-template: auto / 1fr 1fr 1fr;
   gap: var(--defaultG);
   container: aboutNumbers / inline-size;
}

.about-number {
   .number {
      margin-bottom: 35px;
      font-family: var(--decorF);
      font-size: 70px;
      font-weight: 700;
   }

   .title {
      padding-top: 15px;
      font-size: 20px;
      font-weight: 600;
      color: var(--primary);
      border-top: 1px solid var(--border_dark);
   }

   &:hover {
      .number {
         &:after {
            width: 100%;
            -webkit-text-stroke: 1px var(--primary);
         }
      }
   }
}

@media all and (width < 550px) {
   .about-numbers {
      grid-template: auto / 1fr;
      gap: 60px;
   }

   .about-number {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .number {
         margin-bottom: 15px;
         padding-bottom: 20px;
         font-size: clamp(70px, 40cqi, 90px);
         text-align: center;
      }

      .title {
         margin: 0 auto;
         max-width: 330px;
         width: 100%;
         text-align: center;
         font-size: clamp(20px, 7cqi, 23px);
      }
   }
}

