@import './popup-form/popup-form';

.popup-wrap {
   padding: 40px 15px;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   min-height: 100%;
   overflow: auto;
   display: grid;
   grid-template: 1fr / 1fr;
   z-index: 3;
   opacity: 0;
   visibility: hidden;
   transition: all .3s var(--timing_function);
   
   .popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background: var(--overlay);
   }

   .popup {
      margin: auto;
      padding: 50px 40px;
      max-width: 895px;
      width: 100%;
      position: relative;
      align-self: center;
      border-radius: 10px;
      border: 2px solid var(--border_light);
      background: var(--slider_arrow);
      backdrop-filter: var(--input_blur);
      z-index: 4;
      opacity: 0;
      visibility: hidden;
      transform: translateY(30px);
      transition: all .4s var(--timing_function);

      // box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.40);
      container: popup / inline-size;
   }

   .ui-field {
      background: transparent;
   }

   &.active {
      opacity: 1;
      visibility: visible;

      .popup {
         opacity: 1;
         visibility: visible;
         transform: none;
         transition-delay: 0.4s;
      }
   }
}

.popup-title {
   margin-bottom: 15px;
   font-family: var(--titleF);
   font-size: 35px;
   font-weight: 700;
   line-height: 120%;
   color: var(--light);
   text-align: center;
   text-transform: uppercase;
}

.popup-subtitle {
   margin: 0 auto 50px auto;
   font-size: 17px;
   font-weight: 300;
   line-height: 130%;
   color: var(--light);
   text-align: center;
   max-width: 540px;
}


@media all and (max-width: 500px) {
   .popup-wrap {
      .popup {
         padding: 30px 15px;
      }
   }

   .popup-title {
      font-size: 30px;
   }

   .popup-subtitle {
      margin-bottom: 30px;
      font-size: 14.5px;
   }
}