@import 'vars';
@import 'typography';
@import 'mixin';
@import 'functions';

@import '../../components/ui/ui-preloader/ui-preloader';

// VI
@import '../../components/vi/vi-round';
@import '../../components/vi/vi-block/vi-block';
@import '../../components/vi/vi-stroke-text';


// UI
@import '../../components/ui/ui-button/ui-button';
@import '../../components/ui/ui-burger/ui-burger';
@import '../../components/ui/ui-field/ui-field';
@import '../../components/ui/ui-close/ui-close';
@import '../../components/ui/ui-swiper-controls/ui-swiper-controls';
@import '../../components/ui/ui-swiper-controls/ui-swiper-controls';


// Components
@import '../../components/navigation/navigation';
@import '../../components/header/header';
@import '../../components/mobile-menu/mobile-menu';
@import '../../components/form/form';
@import '../../components/socials/socials';
@import '../../components/footer/footer';
@import '../../components/popups/popup';



* {
   -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;
   scroll-behavior: smooth;
   scrollbar-color: var(--action), #000 ; /* «цвет ползунка» «цвет полосы скроллбара» */
   scrollbar-width: thin ;/* толщина */
   box-sizing: border-box;
   font-family: var(--textF);
   font-weight: 400;
   line-height: 100%;
}

// Custom scrollbar for webKit
::-webkit-scrollbar {
   background: transparent;
   width: 5px;
   height: 5px;
}

::-webkit-scrollbar-thumb {
   // background: var(--text_on_dark);
   border-radius: 50px;
}

::-webkit-scrollbar-corner {
   background: transparent;
}

html,body {
   scrollbar-gutter: stable;
   position: relative;
   // all: unset; // сбрасывыает все стили до значений по умолчанию
}

html {
   font-size: 16px;
}

body {
   background: var(--light);
   position: relative;

   &.no-scroll {
      overflow-y: hidden;
   }
}
   
html, body, main {
   width: 100%;
}

main {
   min-height: 100%;
}

i {
   display: inline-block;
   font-family: var(--icon_font);
}

// TODO ui-container
.ui-container {
   margin: 0 auto;
   padding: 0 var(--containerG);
   max-width: calc(var(--containerW) + 2 * var(--containerG));
}

// TODO overlay
.ui-overlay {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: var(--overlay);
   opacity: 0;
   visibility: hidden;
   z-index: 1;
   transition: all .3s ease;

   &.active {
      opacity: 1;
      visibility: visible;
   }
}