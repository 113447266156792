%fadeContent {
   opacity: 0;
   visibility: hidden;
   transform: translateX(30px);
}

%showContent {
   opacity: 1;
   visibility: visible;
   transform: none;
}


.delivery-screen {
   padding: var(--sectionG) 0;
}

.delivery-wrap {
   margin-top: 80px;
   display: flex;
   gap: 10px;
   transition: all .5s ease;
}

.delivery {
   --paddingX: clamp(20px, 9cqi, 50px);
   --paddingY: clamp(30px, 10cqi, 50px);

   background: var(--text_lighter);
   border-radius: 30px;
   min-height: 500px;
   max-height: 500px;
   width: 100px;
   flex: 0 0 100px;
   container: delivery / inline-size;
   cursor: pointer;
   overflow: hidden;
   transition: all .8s var(--timing_function);

   .container {
      padding: var(--paddingY) var(--paddingX);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      &:after {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         border-radius: 30px;
         background: rgba(0, 0, 0, .6);
         opacity: 0;
         transition: all .3s var(--timing_function);
      }
   }

   .title {
      margin-bottom: 30px;
      padding-bottom: 25px;
      font-family: var(--titleF);
      font-size: 50px;
      font-weight: 700;
      line-height: 90%;
      color: var(--light);
      position: relative;
      z-index: 1;
      transition: all .1s var(--timing_function);

      @extend %fadeContent;

      &:after {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         height: 2px;
         width: 70px;
         background: var(--light);
      }
   }

   .text {
      font-size: 18px;
      font-weight: 300;
      line-height: 150%;
      color: var(--light);
      max-width: 660px;
      position: relative;
      z-index: 1;
      transition: all .1s var(--timing_function);

      @extend %fadeContent;
   }

   .ui-btn {
      margin-top: 60px;
      width: max-content;
      position: relative;
      z-index: 1;
      transition: 
         background-color .2s var(--timing_function),
         color .2s var(--timing_function), 
         translate .2s var(--timing_function);

      @extend %fadeContent;
   }

   img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
      transition: all .8s var(--timing_function);
   }


   .arrow {
      --size: 60px;

      position: absolute;
      bottom: 20px;
      right: 20px;
      width: var(--size);
      height: var(--size);
      backdrop-filter: var(--arrows_blur);
      background: var(--slider_arrow);
      border: 1px solid var(--border_controls);
      transition: 
         background-color .2s var(--timing_function),
         opacity .4s var(--timing_function) .8s,
         visibility .4s var(--timing_function) .8s,
         transform .4s var(--timing_function) .8s;
      z-index: 1;

      @extend %showContent;

      i {
         margin-right: -2px;
         font-size: 24px;
         color: var(--light);
         transition: all .2s var(--timing_function);
      }

      &:hover {
         background: var(--light);
         
         i {
            color: var(--primary);
         }
      }
   }

   &.active {
      width: 100%;
      flex: 1 1 100%;

      .container:after {
         opacity: 1;
      }

      .title, .text, .ui-btn {
         @extend %showContent;
      }

      .title { 
         transition: all .4s var(--timing_function) .7s;
      }

      .text { 
         transition: all .4s var(--timing_function) .8s;
      }

      .ui-btn { 
         transition: 
            background-color .2s var(--timing_function),
            color .2s var(--timing_function), 
            translate .2s var(--timing_function),
            opacity .4s var(--timing_function) .9s,
            visibility .4s var(--timing_function) .9s,
            transform .4s var(--timing_function) .9s;
      }

      .arrow {
         transition: 
            background-color .2s var(--timing_function),
            opacity .4s var(--timing_function),
            visibility .4s var(--timing_function),
            transform .4s var(--timing_function);

         @extend %fadeContent;
      }
   }
}

@media all and (width < 900px) {
   .delivery-wrap {
      flex-direction: column;
      width: 100%;
   }

   .delivery {
      width: 100%;
      flex: 0 0 100%;
      min-height: 100px;
      max-height: 100px;
      height: 100%;
      overflow: hidden;

      img {
         top: -170px;
      }

      &.active {
         min-height: auto;
         
         .container {
            img {
               top: 0;
            }
         }
      }

      .title {
         font-size: clamp(40px, 11cqi, 50px);
      }

      .arrow {
         top: 20px;
         bottom: auto;
         rotate: 90deg;
      }
   }
}