.swiper-container {
   position: relative;

   .swiper, .swiper-slide {
      width: 100%;
      height: 100%;
   }

   .swiper-slide {
      position: relative;
   }
}

.swiper-arrows {
   display: flex;
   gap: 10px;

   .swiper-arrow {
      position: static;
   }

   &.center {
      margin-top: 20px;
      align-items: center;
      width: 100%;
      justify-content: center;
      gap: 5px;
   }
}

.swiper-arrow {
   --size: 50px;

   background: rgba(255, 255, 255, .1);
   border: 1px solid rgba(255, 255, 255, .35);
   backdrop-filter: var(--arrows_blur);
   width: var(--size);
   height: var(--size);
   cursor: pointer;
   z-index: 1;

   &:after {
      font-family: var(--icon_font);
      font-size: 18px;
      color: var(--light);
      transition: all .2s var(--timing_function);
   }

   &.prev {
      &:after {
         content: '\e900';
         margin-left: -2px;
      }
   }

   &.next {
      &:after {
         content: '\e911';
         margin-right: -2px;
      }
   }


   &.colorful {
      border: 1px solid rgba(32, 53, 86, .2);

      &:after {
         color: var(--primary);
      }
   }

   &.sm {
      --size: 40px;
   }


   @media (hover: hover) {
      &:hover {
         transform: translateY(-3px);
         background: rgba(32, 53, 86, .1);
         border-color: rgba(32, 53, 86, .2);
   
         &:after {
            // color: var(--primary);
         }
      }
   }

   @media (hover: none) {
      &:active {
         // transform: translateY(-3px);
         background: rgba(32, 53, 86, .1);
         border-color: rgba(32, 53, 86, .2);

         &:after {
            color: var(--primary);
         }
      }
   }
}