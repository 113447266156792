.socials {
   display: flex;
   align-items: center;
   gap: 20px;
   
   &__link {
      display: inline-flex;
      transition: all .2s var(--timing_function);

      i {
         font-size: 18px;
         color: var(--light);
         transition: all .2s var(--timing_function);
      }

      &:hover {
         transform: translateY(-3px);

         i {
            color: var(--action);
         }
      }
   }

   &.words {
      margin-bottom: 30px;
      width: 100%;
      gap: 40px;

      .socials__link {
         font-size: 22px;
         font-weight: 600;
         color: var(--primary);
         position: relative;
         transition: all .1s var(--timing_function);

         &:hover {
            color: var(--action);
         }
      }

      @media all and (height < 800px) {
         margin-top: 100px;
      }
   }
}