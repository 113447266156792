.reviews-screen {
   padding: var(--sectionG) 0;
   background: var(--section_slow);
}

.reviews-container {
   margin-top: 80px;

   .swiper-slide {
      width: 100%;
      height: auto;
      display: flex;
      container-type: inline-size;
   }

   .review {
      --paddingX: clamp(25px, 5cqi, 40px);
      --paddingY: clamp(20px, 7cqi, 50px);

      padding: var(--paddingY) var(--paddingX);
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: var(--light);
      border-radius: var(--defaultR);

      .author {
         margin-bottom: 30px;
         display: flex;
         align-items: center;
         gap: 20px;

         &__img {
            --size: 60px;

            width: var(--size);
            height: var(--size);
            flex: 0 0 var(--size);
            border-radius: 50%;
            box-shadow: 0 0 0 6px rgba(32, 53, 86, .1);
            overflow: hidden;
            position: relative;

            img {
               max-width: 100%;
               height: auto;
            }
         }

         &__name {
            margin-bottom: 5px;
            font-size: clamp(19px, 5cqi, 22px);
            font-weight: 600;
            line-height: 100%;
            color: var(--primary);
         }

         &__position {
            font-size: 15px;
            font-weight: 400;
            line-height: 100%;
            color: var(--text_lighter);
         }
      }

      .text {
         font-size: 16px;
         font-weight: 400;
         line-height: 150%;
         color: var(--primary);
         flex: 1 1 auto;
      }

      .details {
         margin-top: 30px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         gap: 30px;

         .rating {
            max-width: 110px;
            
            img {
               max-width: 100%;
               height: auto;
            }
         }

         .read-more {
            display: flex;
            align-items: center;
            gap: 15px;
            cursor: pointer;

            span {
               font-size: 15px;
               font-weight: 600;
               color: vasr(--primary);
               transition: all .2s var(--timing_function);
            }

            i {
               font-size: 12px;
               color: var(--primary);
               transition: all .2s var(--timing_function);
            }

            &:hover {
               span {
                  color: var(--action);
               }

               i {
                  color: var(--action);
                  transform: translateX(3px);
               }
            }
         }
      }
   }
}