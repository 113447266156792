.action-screen {
   margin-top: var(--sectionG);
   padding: 80px 0 40px 0;
   background: url('../assets/img/action/action.webp') no-repeat center / cover;
   overflow: hidden;
   position: relative;

   &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, .8));
      z-index: 0;
   }

   .ui-container {
      position: relative;
      z-index: 1;
      container: action / inline-size;
   }

   .section-subtitle {
      max-width: 565px;
   }

   .ui-btn {
      margin-top: 60px;
   }

   .running-line-wrap {
      display: flex;
      align-items: center;
      gap: 90px;
      width: max-content;
      position: relative;
      z-index: 1;
   }

   .running-line  {
      margin-top: 90px;
      display: flex;
      align-items: center;
      gap: 90px;
      width: max-content;
      animation: moveRunningLine 15s linear infinite both;
      
      .line {
         font-family: var(--decorF);
         font-size: clamp(70px, 12cqi, 90px);
         font-weight: 700;
         line-height: 130%;
         display: inline-block;
      }
   }

   @media all and (width > 1025px) {
      background-attachment: fixed;
   }

   @media all and (width < 960px) {
      background: url('../assets/img/action/action_960w.webp') no-repeat center / cover;
   }
}


@keyframes moveRunningLine {
   0% { transform: translateX(90px); }
   100% { transform: translateX(-100%); }
}