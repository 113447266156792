@import 'numbers/numbers';

.about-screen {
   padding-top: var(--sectionG);

   .ui-container {
      container: about / inline-size;
   }

   .about-wrap {
      display: grid;
      grid-template: auto / minmax(500px, 1fr) minmax(350px, 1fr);
      gap: calc((100vw - 960px) / (14400 - 960) * (120 - 40) + 40px);
   }

   .about-details {
      .text {
         font-size: 17px;
         font-weight: 400;
         line-height: 160%;
         color: var(--primary);
         text-wrap: balance;

         &:not(:last-child) {
            margin-bottom: 15px;
         }
      }
   }

   .btn-group {
      margin-top: 50px;
      display: flex;
      align-items: center;
      column-gap: 15px;
      row-gap: 10px;
   }


   // Img
   .about-img {
      --mdR: 100px;
      --smR: 40px;

      background: var(--action_secondary);
      border-radius: var(--smR);
      border-top-left-radius: var(--mdR);
      border-bottom-right-radius: var(--mdR);
      overflow: hidden;
      position: relative;

      img {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   
   @media all and (width < 960px) {
      .about-wrap {
         grid-template: auto / 1fr;
         gap: 50px;
      }

      .about-details {
         order: 2;
      }

      .about-img {
         --mdR: 60px;
         --smR: 20px;
         
         order: 1;
         height: 350px;
      }
   }

   @media all and (width < 520px) {
      .btn-group {
         flex-direction: column;
      }

      .ui-btn {
         display: flex;
         max-width: 330px;
         width: 100%;
      }

      .about-details {
         .text {
            font-size: clamp(16px, 5cqi, 18px);
         }
      }
   }
}