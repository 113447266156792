footer {
   background: var(--primary);

   .logo {
      max-width: 140px;

      img {
         max-width: 100%;
         height: auto;
      }
   }
   @media all and (max-width: 900px) {
      padding: 20px 0;

      // .logo {
      //    max-width: 120px;
      // }
   }
}

.footer_container {
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 20px;
}


.copyright {
   padding: 15px 0;
   background: var(--copyright);
   
   .text {
      font-size: 15px;
      font-weight: 300;
      color: rgba(255, 255, 255, .6);
   }

   a.text {
      text-decoration: underline;
      text-decoration-skip-ink: none;
      transition: all .1s var(--timing_function);

      &:hover {
         color: var(--light);
      }
   }

   @media all and (width < 600px) {
      .footer_container {
         flex-direction: column;
         align-items: center;
         gap: 10px;
      }
   }
}