.contact-screen {
   .contact-container {
      padding: var(--sectionG) 0;
      background: url('../assets/img/contact/contact.webp') no-repeat center / cover;
      position: relative;
      z-index: 1;

       &:after {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background: linear-gradient(to bottom, rgba(0, 0, 0, .4), rgba(0, 0, 0, .8));
      }
   }

   .ui-container {
      position: relative;
      z-index: 1;
   }

   @media all and (width > 1025px) {
      background-attachment: fixed;
   }

   @media all and (width < 960px) {
      .contact-container {
         background: url('../assets/img/contact/contact_960w.webp') no-repeat center / cover;
      }
   }
}

.contact-form-wrap {
   margin: 60px auto 90px auto;
   max-width: 815px;
}





.map {
   --mapH: 365px;

   margin-top: calc(-1 * var(--mapH) / 2);
   margin-bottom: 50px;
   padding: 10px;
   width: 100%;
   height: var(--mapH);
   background: rgba(32, 53, 86, .1);
   backdrop-filter: blur(10px);
   // border-radius: 40px;
   border-radius: var(--defaultR);

   iframe {
      border-radius: 30px;
      border-radius: 10px;
   }
}



.contact-links-container {
   padding-bottom: var(--sectionG);
   position: relative;
   z-index: 1;
}

.contact-links {
   display: grid;
   grid-template: auto / 1fr 1fr 1fr;
   gap: var(--defaultG);

   @media all and (width < 1000px) { 
      --paddingX: clamp(20px, 7cqi, 50px);
      --paddingY: clamp(40px, 10cqi, 70px);

      grid-template: auto / repeat(auto-fit, minmax(310px, 1fr));
   }

   @media all and (width < 340px) { 
      grid-template: auto / repeat(auto-fit, minmax(240px, 1fr));
   }
}


.contact-link {
   --paddingX: clamp(15px, 8cqi, 40px);
   --size: 70px;
   --iconSize: 23px;

   .container {
      padding: var(--paddingX);
      border: 1px solid transparent;
   }

   .icon {
      margin-bottom: 30px;
      width: var(--size);
      height: var(--size);
      

      i {
         font-size: var(--iconSize);
         
      }
   }

   .title {
      margin-bottom: 20px;
      font-size: clamp(18px, 7cqi, 22px);
   }

   .text {
      font-size: 15px;
      max-width: 330px;
   }

   &.colorful {
      .title {
         position: relative;
         white-space: nowrap;
         max-width: max-content;

         &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 0;
            height: 1px;
            background: var(--action);
            transition: var(--transition);
         }
      }

      &:hover {
         translate: 0 -3px;

         .container {
            background: transparent;
            border: 1px solid rgba(32, 53, 86, 0.1);
         }

         .icon {
            background: var(--action);

            i {
               color: var(--light);
            }
         }

         .title {
            color: var(--action);
         
            &:after {
               width: 100%;
            }
         }
      }
   }
}
