.form {
   display: grid;
   grid-template: auto / 1fr;
   gap: var(--filedsG);
   container: formC / inline-size;

   .grid-item {
      display: grid;
      gap: var(--filedsG);

      &.c_2 {
         grid-template: auto / 1fr 1fr;
      }
   }

   .message-status {
      margin-top: 30px;
      padding: 12px 20px;
      display: none;
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
      text-transform: uppercase;
      border-radius: 5px;

      &.success {
         background: var(--success_bg);
         border-left: 4px solid var(--success);
         display: block;
         color:  var(--success);
      }

      &.error {
         background: var(--error_bg);
         border-left: 4px solid var(--error);
         display: block;
         color: var(--error);
      }
   }

   @container (max-width: 630px) {
      .grid-item {
         &.c_2 {
            grid-template: auto / 1fr;
         }
      }
   }

   @container (max-width: 420px) {
      .form-submit {
         flex-direction: column;
         align-items: flex-start;
         gap: 10px;

         .text {
            max-width: 100%;
         }

         .ui-btn {
            width: 100%;
         }
      }
   }
}