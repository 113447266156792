.features-screen {
   padding-top: var(--sectionG);
}


.features {
   --transitionF: .3s var(--timing_function);
   --paddingX: clamp(20px, 12cqi, 50px);
   --paddingY: clamp(40px, 14cqi, 70px);
   --size: clamp(90px, 26cqi, 110px);
   --iconSize: 50px;

   display: grid;
   grid-template: auto / 1fr 1fr 1fr;
   gap: var(--defaultG);

   &__item {
      .container {
         padding: var(--paddingX);
      }

      .icon {
         margin-bottom: 40px;
         width: var(--size);
         height: var(--size);

         i {
            font-size: var(--iconSize);
         }
      }

      .title {
         margin-bottom: 25px;
         font-size: 23px;
      }

      .text {
         font-size: 16px;
         max-width: 360px;
      }
   }

   @media all and (width < 1000px) { 
      --paddingX: clamp(20px, 7cqi, 50px);
      --paddingY: clamp(40px, 10cqi, 70px);

      grid-template: auto / repeat(auto-fit, minmax(310px, 1fr));
   }

   @media all and (width < 340px) { 
      grid-template: auto / repeat(auto-fit, minmax(240px, 1fr));
   }
}