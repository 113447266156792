:root {
   // Colors
   --light: #fff;
   --primary: #203556;
   --title: #161A1D;
   --action: #02AAEC;
   --action_secondary: #E9EBEF;
   --section_slow: #F8F9FA;
   --icon_bg: #E2E5E9;
   --border_light: rgba(255, 255, 255, .2);
   --border_dark: rgba(2, 1, 1, .0784);
   --border_controls: rgba(255, 255, 255, .35);
   --border_error: #A40000;
   --border_decor: rgba(211, 253, 249, .71);
   --error: #FA9F9F;
   --error_bg: rgba(215, 147, 147, .5);
   --success: #0FB800;
   --success_bg: #D2F4CF;
   --text_lighter: #79869A;
   --copyright: #0B1B33;
   --slider_arrow: rgba(255, 255, 255, .1);
   --overlay: rgba(0, 0, 0, .6);
   --action_line: #D3FDF9;

   --containerW: 1420px;


   // Fonts
   --titleF: 'Futura New', sans-serif;
   --textF: Jost, sans-serif;
   --decorF: Oswald, sans-serif;
   --icon_font: 'TTC';

   // Gutter 
   --defaultG: 20px;
   --containerG: 15px;
   --sectionG: 160px;

   @media all and (width < 900px) {
      --sectionG: 120px;
   }

   // Radius
   --defaultR: 20px;
   --controlR: 60px;
   --filedsG: 15px;


   // Shadows
   

   // Effects
   --input_blur: blur(20px);
   --btn_blur: blur(20px);
   --arrows_blur: blur(10px);


   // Transitions
   --timing_function: ease-out;
}