.section-title {
   --size: clamp(120px, 10cqi, 148px);
   --pos: calc(-1 * var(--size) / 2);

   margin-bottom: 60px;
   padding-bottom: 40px;
   font-family: var(--titleF);
   font-size: 115px;
   font-weight: 700;
   line-height: 80%;
   position: relative;
   color: var(--title);
   border-bottom: 1px solid var(--border_dark);

   &:after {
      content: '';
      position: absolute;
      top: var(--pos);
      left: var(--pos);
      width: var(--size);
      height: var(--size);
      background: url('../assets/img/decor/title_dark.svg'), no-repeat center / contain;
   }

   &.md {
      margin-bottom: 30px;
      padding-bottom: 0;
      font-size: 65px;
      font-weight: 700;
      line-height: 105%;
      color: var(--light);
      text-transform: uppercase;
      max-width: 880px;
      border: none;
   }

   &.center {
      text-align: center;

      &::after { display: none; }

      span {
         font: inherit;
         display: inline-block;
         position: relative;

         &:after {
            content: '';
            position: absolute;
            top: var(--pos);
            left: var(--pos);
            width: var(--size);
            height: var(--size);
            background: url('../assets/img/decor/title_dark.svg'), no-repeat center / contain;
         }
      }
   }

   &.light {
      color: var(--light);
      border-color: var(--border_light);

      span {
         &:after {
            background: url('../assets/img/decor/title_light.svg'), no-repeat center / contain;
         }
      }
   }

   @media all and (width < 710px) {
      margin-bottom: 40px;
      font-size: clamp(43px, 15cqi, 115px);

      &.md {
         font-size: clamp(30px, 12cqi, 65px);
      }
   }
}

.section-subtitle {
   font-size: 30px;
   font-weight: 400;
   line-height: 120%;
   color: var(--primary);
   max-width: 705px;

   @media all and (width < 710px) {
      font-size: clamp(25px, 5cqi, 30px);
   }

   &.sm {
      font-size: 18px;
      font-weight: 300;
      line-height: 130%;
      color: var(--light);
   }

   &.md {
      margin: 0 auto;
      font-size: 25px;
      font-weight: 300;
      line-height: 120%;
      color: var(--light);
      max-width: 800px;
      text-align: center;
   }

   &.center {
      margin: 0 auto;
      text-align: center;
   }

   &.light {
      color: var(--light);
   }
}