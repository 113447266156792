.ui-close {
   --size: 40px;
   --iconSize: 15px;

   width: var(--size);
   height: var(--size);
   position: absolute;
   top: calc(-1 * var(--size) / 2);
   right: calc(-1 * var(--size) / 2);
   background: var(--slider_arrow);
   border-radius: 50%;
   border: 1px solid var(--border_controls);
   backdrop-filter: var(--arrows_blur);
   cursor: pointer;
   transition: all .2s var(--timing_function);

   &:before {
      content: '\e912';
      font-family: var(--icon_font);
      font-size: var(--iconSize);
      color: var(--light);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: inherit;
   }

   &:hover {
      &:before {
         color: var(--action);
      }
   }

   &.mobile_menu {
      position: absolute;
      right: 20px;
      top: 50px;

      background: rgba(32, 53, 86, .1);
      border-color: rgba(32, 53, 86, .2);

      &:before {
         color: var(--primary);
      }
   }

   @media all and (width < 975px) {
      right: 15px;
   }
}