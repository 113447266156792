.navigation {
   &__list {
      display: flex;
      align-items: center;
      gap: 1px;
   }

   &__item {
      a {
         margin-bottom: -30px;
         padding: 40px 15px 70px 15px;
         font-size: 14px;
         font-weight: 600;
         color: var(--light);
         letter-spacing: 1px;
         text-transform: uppercase;
         position: relative;
         display: flex;

         &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            clip-path: polygon(100% 0, 100% 100%, 0 calc(100% - 30px), 0 0);
            background: rgba(2, 170, 236, .4);
            backdrop-filter: blur(10px);
            transition: all .3s var(--timing_function);
         }
      }

      span {
         position: relative;
         z-index: 1;
      }

      &:hover {
         a:after {
            height: 100%;
         }
      }
   }

   @media all and (width < 900px) {
      display: none;
   }
}