.ui-btn {
   padding: 10px 30px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   min-height: 55px;
   border-radius: var(--controlR);
   font-size: 14px;
   font-weight: 600;
   text-transform: uppercase;
   white-space: nowrap;
   transition: 
      color .2s ease,
      background-color .2s ease,
      opacity .5s ease,
      visibility .5s ease,
      translate .2s ease;

   &:hover {
      translate: 0 -3px;
   }

   &.btn_blur {
      background: rgba(255, 255, 255, .2);
      border: 1px solid rgba(255, 255, 255, .35);
      backdrop-filter: var(--btn_blur);
      color: var(--light);

      &:hover {
         color: var(--primary);
         background: var(--light);
      }
   }

   &.btn_action {
      background: var(--primary);
      color: var(--light);

      &:hover {
         color: var(--light);
         background: var(--copyright);
      }
   }

   &.btn_slow {
      background: var(--action_secondary);
      color: var(--primary);

      &:hover {
         color: var(--light);
         background: var(--primary);
      }
   }

   &.btn_light {
      background: var(--light);
      color: var(--primary);

      &:hover {
         color: var(--light);
         background: var(--primary);
      }
   }
} 