.vi-stroke-text {
   color: transparent;
   text-transform: uppercase;
   white-space: nowrap;
   position: relative;

   &:after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      overflow: hidden;
   }

   &:hover:after {
      width: 100%;
   }

   // action scrreen
   &.action_text {
      --stroke: 2px var(--border_decor);
      -webkit-text-stroke: var(--stroke);

      &:after {
         color: var(--action_line);
         transition: all 3s var(--timing_function);
      }

      &:hover:after {
         -webkit-text-stroke: var(--stroke);
      }
   }


   &.basic {
      --stroke: 1px var(--primary);
      -webkit-text-stroke: var(--stroke);

      &:after {
         color: var(--primary);
         transition: all .5s var(--timing_function);
      }

      &:hover:after {
         -webkit-text-stroke: var(--stroke);
      }
   }
   
}