header {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 2;
   transition: all .3s var(--timing_function);

   .ui-container {
      @extend %flexBetween;
      gap: 30px;
      border-bottom: 1px solid var(--border_light);
   }

   .header-mobile-wrap {
      margin-right: auto;
      display: flex;
      align-items: center;
      gap: 30px;
   }

   .logo {
      max-width: 155px;
      transition: all .3s ease;

      img {
         max-width: 100%;
         height: auto;
      }
   }
}

.phone-wrap {
   position: relative;
   transition: all .2s ease;
}


@media all and (width < 900px) {
   header {
      .ui-container {
         padding: 15px 10px;
      }
   }

   header .logo, header.active .logo {
      max-width: 120px;
   }
}

@media all and (width < 490px) {
   header {
      .header-mobile-wrap {
         width: 100%;
         justify-content: space-between;
      }

      .ui-btn {
         display: none;
      }

      .logo {
         order: 1;
      }

      .ui-burger {
         order: 2;
      }
   }
}

