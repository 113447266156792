.mobile-menu-wrap {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 0;
   background: var(--light);
   overflow: auto;
   z-index: 3;
   transition: all .4s ease;

   &::-webkit-scrollbar {
      display: none;
   }
   
   &.active {
      height: 100%;
   }

   .mobile-menu {
      padding: 40px clamp(20px, 6cqi, 40px);
      background: var(--dark);
      width: 100%;
      position: relative;
      z-index: 1;
   }

   .mobile-logo {
      max-width: 190px;
      display: inline-block;

      img {
         max-width: 100%;
         height: auto;
      }
   }

   // Меню
   .mobile-nav {
      margin-top: 60px;
      margin-bottom: 80px;

      &__item {
         a {
            font-family: var(--decorF);
            font-size: clamp(30px, 13cqi, 75px);
            font-weight: 700;
            line-height: 149%;
         }
      }
   }

   // Кнопки
   .btn-group {
      margin-top: 30px;
      display: flex;
      align-items: flex-start;
      gap: 15px;
   }

   // Contact links
   .contact-links {
      margin-top: 80px;
   }

   .socials {
      margin: 0;
   }

   @media all and (width < 570px) {
      .btn-group {
         flex-direction: column;
         align-items: flex-start;
         gap: 10px;

         .ui-btn {
            max-width: 350px;
            width: 100%;
         }
      }
   }

   @media all and (width < 450px) {
      .mobile-nav {
         margin-bottom: 60px;

         &__item {
            &:not(:first-child) {
               margin-bottom: -5px;
            }
         }
      }

      .mobile-logo {
         max-width: 150px;
      }
   }

   @media all and (width < 340px) {
      .mobile-menu {
         padding: 40px 15px;
      }
   }

}