.steps-screen {
   margin-top: var(--sectionG);
   padding: var(--sectionG) 0;
   background: var(--section_slow);
}

.steps {
   --gap: 30px;
   --transitionIconTime: .5s;
   --transitionLineTime: 1s;

   margin-top: 120px;
   display: grid;
   grid-template: auto / 1fr 1fr 1fr;
   gap: var(--gap);

   .step {
      --size: clamp(120px, 25cqi, 200px);
      --iconSize: clamp(45px, 7cqi, 60px);
      --sizeIconR: calc(var(--size) - 20%);

      position: relative;

      &:not(:last-child) {
         &:after {
            content: '';
            position: absolute;
            top: calc(var(--size) / 2 - 1.5px);
            left: calc(var(--size) - 10px);
            width: 0;
            height: 3px;
            background: #E8EBEE;
            transition: all var(--transitionLineTime) var(--timing_function) .25s;
         }
      }

      .icon-wrap {
         margin-bottom: 60px;
         width: var(--size);
         height: var(--size);
         flex: 0 0 var(--size);
         background: #E8EBEE;
         position: relative;
         z-index: 1;
         opacity: 0;
         transform: scale(.7);
         transition: all var(--transitionIconTime) var(--timing_function);
      }

      .step-index {
         --size: 55px;

         width: var(--size);
         height: var(--size);
         font-size: 26px;
         font-weight: 600;
         line-height: 80%;
         color: var(--light);
         background: var(--primary);
         position: absolute;
         top: -5px;
         left: -5px;
         z-index: 1;
      }

      .icon {
         width: var(--sizeIconR);
         height: var(--sizeIconR);
         background: var(--light);
         box-shadow: 0 0 30px 2px rgba(32, 53, 86, .04);
         position: relative;

         i {
            font-size: var(--iconSize);
            color: var(--primary);
         }

         &:before {
            --sizeB: calc(100% - 30px) ;

            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: var(--sizeB);
            height: var(--sizeB);
            transform: translate(-50%, -50%);
            border: 3px solid var(--action);
            border-radius: 50%;
         } 
      }

      .step-details {
         opacity: 0;
         transform: translateY(10px);
         transition: all var(--transitionIconTime) var(--timing_function) .3s;
      }

      .title {
         margin-bottom: 20px;
         font-size: 25px;
         font-weight: 600;
         line-height: 95%;
         color: var(--primary);
      }

      .text {
         font-size: 17px;
         font-weight: 400;
         line-height: 120%;
         color: var(--primary);
         max-width: 360px;
      }

      &.animate {
         $delay: 0s;
         $delayLine: .5s;

         @for $i from 2 through 3 {
            $delay: $delay + 1s;
            $delayLine: $delayLine + 1s;

            &:nth-child(#{$i}) {
               .icon-wrap {
                  transition-delay: $delay;
               }

               .step-details {
                  transition-delay: #{$delay + .3s};
               }

               &:not(:last-child):after {
                  transition-delay: $delayLine;
               }
            }
         }

         &.animation {
            .icon-wrap, .step-details {
               opacity: 1;
               transform: none;
            }

            &:not(:last-child):after {
               width: calc(100% +  var(--gap));
            }
         }
      } 
   }

   @media all and (width < 900px) {
      grid-template: auto / 1fr;
      gap: 0;

      .step {
         padding-bottom: 100px;
         display: flex;
         align-items: center;
         gap: 20px;

         .icon-wrap {
            margin-bottom: 0;
         }

         .title {
            font-size: clamp(23px, 6cqi, 30px);
         }

         .text {
            max-width: 510px;
            font-size: clamp(17px, 5cqi, 19px);
         }

         &:not(:last-child) {
            &:after {
               left: calc(var(--size) / 2 - 1.5px);
               width: 3px;
               height: 0;
            }
         }

         &.animate {
            &.animation {
               &:not(:last-child) {
                  &:after {
                     width: 3px;
                     height: 100%;
                  }
               }
            }
         }
      }
   }


   @media all and (width < 490px) {
      gap: 60px;

      .step {
         --size: 170px;

         padding-bottom: 0;
         flex-direction: column;

         .title, .text {
            text-align: center;
         }

         .text {
            margin: 0 auto;
         }

         &:not(:last-child) {
            &:after {
               display: none;
            }
         }

         &.animate {
            $delay: 0s;
   
            @for $i from 2 through 3 {
               $delay: $delay + .3s;
   
               &:nth-child(#{$i}) {
                  .icon-wrap {
                     transition-delay: $delay;
                  }
   
                  .step-details {
                     transition-delay: #{$delay + .3s};
                  }
               }
            }
   
            &.animation {
               .icon-wrap, .step-details {
                  opacity: 1;
                  transform: none;
               }
            }
         } 
      }
   }
}