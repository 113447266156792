.vi-block {
   --transition: all .2s var(--timing_function);

   display: flex;
   width: 100%;
   container-type: inline-size;
   opacity: 0;
   transform: translateY(20px);
   transition: 
      opacity .5s var(--timing_function),
      translate .2s var(--timing_function),
      opacity .5s var(--timing_function),
      transform .5s var(--timing_function);

   .container {
      padding: var(--paddingX);
      border-radius: var(--defaultR);
      width: 100%;
      transition: var(--transition);
   }

   .icon {
      margin-bottom: 40px;
      width: var(--size);
      height: var(--size);
      background: var(--icon_bg);
      box-shadow: 0 0 0 9px rgba(32, 53, 86, .03);
      transition: var(--transition);

      i {
         font-size: var(--iconSize);
         color: var(--primary);
         transition: var(--transition);
      }
   }

   .title {
      margin-bottom: 25px;
      font-size: 23px;
      font-weight: 600;
      line-height: 100%;
      color: var(--primary);
      transition: var(--transition);
   }

   .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      color: var(--primary);
      max-width: 360px;
   }

   &.colorful {
      .container {
         background: rgba(32, 53, 86, .1);
      }

      .icon {
         background: var(--light);
         box-shadow: 0 0 20px 9px rgba(32, 53, 86, .03);
      }
   }

   &.animate {
      $delay: 0s;

      @for $i from 2 through 6 {
         $delay: $delay + .1s;

         &:nth-child(#{$i}) {
            transition: 
               translate .2s var(--timing_function),
               opacity .5s var(--timing_function) $delay,
               transform .5s var(--timing_function) $delay;
         }
      }

      &.animation {
         opacity: 1;
         transform: none;
      }
   } 
}