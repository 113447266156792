%slideActiveContent {
   opacity: 1;
   visibility: visible;
   transform: none;
}

%slideContent {
   opacity: 0;
   visibility: hidden;
}

.hero-screen {
   width: 100%;

   .hero_swiper-container {
      height: 100%;
   }

   .swiper-slide {
      height: auto;
   }

   .slide_container {
      padding: 80px 10px 50px 10px;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;
   }

   .hero-title {
      margin-bottom: 40px;
      font-family: var(--titleF);
      font-size: 65px;
      font-weight: 700;
      line-height: 110%;
      color: var(--light);
      text-transform: uppercase;
      max-width: 980px;
      position: relative;
      z-index: 1;
      animation: fadeSlideContent .6s ease forwards;
   }

   .hero-text {
      font-size: 18px;
      font-weight: 300;
      line-height: 130%;
      color: var(--light);
      max-width: 670px;
      position: relative;
      z-index: 1;
      animation: fadeSlideText .6s ease .1s forwards;
   }

   .ui-btn {
      margin-top: 70px;
      position: relative;
      z-index: 2;
      max-width: max-content;
      transition: 
         color .2s ease,
         background-color .2s ease, 
         translate .2s ease;
      animation: fadeSlideContent .6s ease .2s forwards;
   }

   img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 20% 50%;
      transform: none;
      transition: all 11s ease-out;
   }

   .swiper-slide-active {
      .hero-title {
         opacity: 0;
         animation: fadeSlideActiveContent .6s ease .3s forwards;
      }

      .hero-text {
         opacity: 0;
         animation: fadeSlideActiveText .6s ease .4s forwards;
      }

      .ui-btn {
         opacity: 0;
         animation: fadeSlideActiveContent .65s ease .6s forwards;
      }

      img {
         transform: scale(1.25);
      }
   }

   .swiper-arrows {
      padding: 0 10px;

      position: absolute;
      right: calc((100% - var(--containerW)) / 2);
      bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 1;

      @media all and (width < 1430px) {
         right: 10px;
      }
      
   }

   @media all and (width < 700px) {
      .hero-title {
         font-size: clamp(23px, 10cqi, 65px);

         // br { display: none; }
      }

      .hero-text {
         font-size: clamp(16px, 5cqi, 18px);
      }
   }

   // @media all and ((width < 650px) and (height < 650px)), (height < 650px) {}

   @media all and (height > 650px) {
      .swiper-slide {
         height: 100svh;
      }

      .slide_container {
         padding-top: 50px;
         height: 100%;
      }

      .swiper-arrows {
         bottom: 60px;
      }
   }


   @media all and (height < 630px) and (width > 650px) {
      .swiper-arrows {
         bottom: auto;
         top: 50%;
         transform: translateY(-50%);
      }
   }

   @media all and (height > 630px) and (width < 650px) {
      .swiper-arrows {
         bottom: 20px;
      }
   }
}




@keyframes fadeSlideText {
   0% { opacity: 1; transform: none;}
   100% { opacity: 0; transform: translateX(-200px);}
}

@keyframes fadeSlideActiveText {
   0% { opacity: 0; transform: translateX(200px);}
   100% { opacity: 1; transform: none;}
}



@keyframes fadeSlideContent {
   0% { opacity: 1; transform: none;}
   100% { opacity: 0; transform: translateX(-100px);}
}

@keyframes fadeSlideActiveContent {
   0% { opacity: 0; transform: translateX(100px);}
   100% { opacity: 1; transform: none;}
}