.ui-field {
   padding: 16px 25px;
   font-size: 16px;
   font-weight: 400;
   color: var(--light);
   background: var(--slider_arrow);
   border: 1px solid var(--border_controls);
   border-radius: var(--controlR);
   width: 100%;
   height: 60px;
   backdrop-filter: var(--input_blur);
   transition: 
      background-color .2s var(--timing_function),
      border-color .2s var(--timing_function),
      color .2s var(--timing_function);

   &::placeholder {
      font-weight: 300;
      color: rgba(255, 255, 255, .6);
   }

   &:hover {
      border: 1px solid var(--border_controls);
   }

   &:focus {
      border: 1px solid var(--light);
   }
}

textarea.ui-field {
   min-height: 120px;
   border-radius: 25px;
}

.form-item {
   position: relative;

   .error-message {
      position: relative;
      top: 5px;
      color: var(--error);
      font-size: 15px;
      font-weight: 400;
      display: none;
   }

   &.error {
      .ui-field {
         background: var(--error_bg);
         color: var(--error);
         border-color: var(--border_error);

         &::placeholder {
            color: var(--error);
         }
      }

      .error-message {
         display: inline-block;
      }
   }
}

.form-submit {
   margin-top: 20px;
   display: flex;
   align-items: center;
   gap: 40px;

   .text {
      font-size: 14px;
      font-weight: 300;
      line-height: 130%;
      color: rgba(255, 255, 255, .6);
      max-width: 300px;

      a {
         font-weight: 600;
         color: var(--light);
         text-decoration: underline;
         text-decoration-skip-ink: none;
         cursor: pointer;
         transition: all .1s ease;

         &:hover {
            color: var(--action);
         }
      }
   }
}